import { Container, Header } from "@amzn/awsui-components-react";
import React, { FC } from "react";

const NotFoundPage: FC = () => {
  return (
    <Container header={<Header variant="h2">404 Page not found!</Header>}>
      Sorry, the page you were looking for does not exist.
    </Container>
  );
};

export default NotFoundPage;
