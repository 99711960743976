import { Header } from "common/components";
import {
  API_SEARCH_PATH,
  ARTICLE_TYPE_PATH,
  CATEGORIES_PATH,
  NEW_ARTICLES_PATH,
  SITEMAP_PATH,
  SUPPORT_GROUP_PATH,
  UPDATED_ARTICLES_PATH,
} from "config/route-constants";
import React, { FC, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import ArticlesByCategory from "view/Page/ArticlesByCategory";
import ArticlesByDate from "view/Page/ArticlesByDate";
import SearchResultsPage from "view/Page/SearchResults";
import Sitemap from "view/Page/Sitemap";

import { useAppContext } from "../../AppContext";
import ClickstreamHelper, { getFullUrl, Language } from "../../lib/helpers/clickstream-helper";
import { MetadataType } from "../../lib/interfaces/metadata";
import LegoPage from "../Page/LegoPage";

const Router: FC = () => {
  const location = useLocation();
  const { clickStreamUrls, setClickStreamUrls } = useAppContext();
  useEffect(() => {
    setClickStreamUrls((prevState) => {
      return {
        url: getFullUrl(window.location.href),
        referalUrl: prevState.url,
      };
    });
  }, [location, setClickStreamUrls]);

  useEffect(() => {
    ClickstreamHelper.createClickStreamMetric({
      language: Language.EN, // Currently we only support English as the main language
      ...clickStreamUrls,
    });
  }, [clickStreamUrls]);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(props) => LocationChangeEventWrapper(<LegoPage {...props} path="/landing-page" key={Date.now()} />)}
      />
      <Route path={SITEMAP_PATH} render={(props) => LocationChangeEventWrapper(<Sitemap {...props} />)} />
      <Route
        path={API_SEARCH_PATH}
        render={(props) => LocationChangeEventWrapper(<SearchResultsPage {...props} key={Date.now()} />)}
      />
      <Route
        path={NEW_ARTICLES_PATH}
        render={(props) =>
          LocationChangeEventWrapper(
            <ArticlesByDate {...props} header={<Header>{"New Articles"}</Header>} filter="created_at" />
          )
        }
      />
      <Route
        path={UPDATED_ARTICLES_PATH}
        render={(props) =>
          LocationChangeEventWrapper(
            <ArticlesByDate {...props} header={<Header>{"Recently Updated Articles"}</Header>} filter="updated_at" />
          )
        }
      />
      <Route
        path={CATEGORIES_PATH}
        render={(props) => LocationChangeEventWrapper(<ArticlesByCategory {...props} type={MetadataType.CATEGORY} />)}
      />
      <Route
        path={ARTICLE_TYPE_PATH}
        render={(props) =>
          LocationChangeEventWrapper(<ArticlesByCategory {...props} type={MetadataType.ARTICLE_TYPE} />)
        }
      />
      <Route
        path={SUPPORT_GROUP_PATH}
        render={(props) =>
          LocationChangeEventWrapper(<ArticlesByCategory {...props} type={MetadataType.SUPPORT_GROUP} />)
        }
      />
      {/* Changed key to 'window.location.pathname' to ensure that
     the route is not re-triggerd when 'window.location.hash' is changed.
     This prevents 'Tab' widget from refreshing the window everytime the tab is switched
     e.g. if the url changes from '/page#tab-body-1' to '/page#tab-body-2', it won't
     re-trigger a route change since '/page' (window.location.pathname) is same even though
     window.location.hash has change from '#tab-body-1' to '#tab-body-2' */}
      <Route
        path="/*"
        render={(props) => LocationChangeEventWrapper(<LegoPage {...props} key={window.location.pathname} />)}
      />
    </Switch>
  );
};

/**
 * Dispatches a 'locationchange' event whenever the router changes routes.
 * Will be used to detect url changes on a page
 */
const LocationChangeEventWrapper = (componentToRender: React.ReactNode) => {
  window.dispatchEvent(new Event("locationchange"));
  return componentToRender;
};

export default Router;
