export default class DebounceHelper {
  /** Debouncer function */
  static getDebouncer = (timeout: number, callback: (...params: any[]) => any, runImmediately: boolean = false) => {
    let timer: NodeJS.Timeout | undefined = undefined;

    return function (this: any, ...args: any[]) {
      if (timer === undefined && runImmediately) {
        callback(args);
      }
      clearTimeout(timer);
      timer = setTimeout(() => callback(args), timeout);
      return timer;
    };
  };
}
