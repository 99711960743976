import Cookies from "universal-cookie";

import { COOKIE_USERNAME } from "../config/constants";

export const getUsername = (): string | null | undefined => {
  const cookies = new Cookies();
  return cookies.get(COOKIE_USERNAME);
};

export const hasUsername = (): boolean => {
  return !!getUsername();
};
