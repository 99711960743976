import "./style.scss";

import React, { FC } from "react";
import { Link } from "react-router-dom";

const Footer: FC = () => {
  return (
    <div className="kb-footer">
      <div className="footer-content">
        <a
          href="https://w.amazon.com/bin/view/ITservices/Content/ITSE-KB/Intake/Update_Existing_Article"
          target="_blank"
          rel="noopener noreferrer"
        >
          Request an article update
        </a>
        <Link to="/sitemap">Sitemap</Link>
      </div>
    </div>
  );
};

export default Footer;
