import { lazy } from "react";

/**
 * For every partial we want to support we will add a new entry here
 * The key represents the partial name used in Lego
 * e.g. for {{ cms:partial:shared/editbutton }} the key will be "editbutton"
 * The value will be the absolute path for the corrensponding partial created in react
 */

/* istanbul ignore next */
export const PARTIALS_MAP: Record<string, any> = {
  editbutton: lazy(() => import("modules/components/partials/EditButton")),
  article_feedback: lazy(() => import("modules/components/partials/FeedbackWidgetPartial")),
  landing_page_new_articles: lazy(() => import("modules/components/partials/LandingPageNewArticles")),
  landing_page_recently_updated: lazy(() => import("modules/components/partials/LandingPageRecentlyUpdated")),
  landing_page_header: lazy(() => import("modules/components/LandingPageHeader")),
  lego_edited_date: lazy(() => import("modules/components/partials/EditedDate")),
  related_articles: lazy(() => import("modules/components/partials/RelatedArticles")),
};
