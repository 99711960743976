import "./style.scss";

import UrlHelper from "helpers/url-helper";
import LoadingScreen from "modules/components/LoadingScreen";
import { useMetadataArticlesFilter } from "modules/hooks";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { MetadataType } from "../../../lib/interfaces/metadata";
import InternalServerErrorPage from "../InternalServerError";

interface SitemapProps {}

const Sitemap = (props: SitemapProps) => {
  const { metadataByCategory, isError, isLoading } = useMetadataArticlesFilter({ filter: MetadataType.CATEGORY });
  const sortedCategories = Object.keys(metadataByCategory).sort();

  if (isLoading) {
    return <LoadingScreen show={true} />;
  }

  if (isError) {
    return (
      <InternalServerErrorPage message="Sorry, we were unable to retrieve sitemap data. Please try again later." />
    );
  }

  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href={UrlHelper.getLegoStylesUrl()} />
      </Helmet>

      <div className="sitemap">
        <h2>{"Sitemap"}</h2>

        <div className="categories-container">
          {sortedCategories.map((category, categoryIndex) => {
            const articles = metadataByCategory[category];

            return (
              <section key={category} className="category-item" aria-labelledby={`category-${categoryIndex}-header`}>
                <header id={`category-${categoryIndex}-header`}>
                  <h3>{category}</h3>
                </header>
                <ol>
                  {articles.map((article, articleIndex) => {
                    const { hash, pathname, search } = new URL(article.full_path);
                    return (
                      <li key={`${categoryIndex}-${articleIndex}`}>
                        <Link to={{ hash, pathname, search }}>{article.title}</Link>
                      </li>
                    );
                  })}
                </ol>
              </section>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Sitemap;
