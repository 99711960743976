import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import React from "react";
import { Modal } from "react-bootstrap";

type props = {
  show: boolean;
};

const LoadingScreen = ({ show }: props) => {
  return (
    <Modal fullscreen={true} centered show={show} className="loading-screen-modal">
      <Modal.Body className="loading-screen-body">
        <Spinner size="large" />
      </Modal.Body>
    </Modal>
  );
};

export default LoadingScreen;
