import "@amzn/awsui-global-styles";
import "common/styles/style";

import App from "App";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { AppContextProvider } from "./AppContext";

document.body.style.margin = "0";
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("app")
);
