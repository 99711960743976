import React, { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useState } from "react";

import { hasUsername } from "./helpers/profile-helper";
import { ClickStreamUrls, getFullUrl, IMetricsData } from "./lib/helpers/clickstream-helper";

export interface IAppContextProps {
  // High-level app loader
  isUserProfileReady: boolean;
  setUserProfileReady: Dispatch<SetStateAction<boolean>>;
  clickStreamUrls: Pick<IMetricsData, "url" | "referalUrl">;
  setClickStreamUrls: Dispatch<SetStateAction<ClickStreamUrls>>;
}

export const AppContext = createContext<IAppContextProps | null>(null);

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext must be used within Provider");
  }
  return context;
};

export interface AppContextProviderProps {}

export const AppContextProvider: FC<PropsWithChildren<AppContextProviderProps>> = ({ children: providerChildren }) => {
  const [isUserProfileReady, setUserProfileReady] = useState<boolean>(hasUsername());
  const [clickStreamUrls, setClickStreamUrls] = useState<ClickStreamUrls>({
    url: getFullUrl(window.location.href),
    referalUrl: getFullUrl(window.document.referrer),
  });

  return (
    <AppContext.Provider
      value={{
        isUserProfileReady,
        setUserProfileReady,
        clickStreamUrls,
        setClickStreamUrls,
      }}
    >
      {providerChildren}
    </AppContext.Provider>
  );
};
