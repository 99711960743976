import UrlHelper from "../../helpers/url-helper";
import HttpClient from "../clients/HttpClient";

export type ClickStreamUrls = Pick<IMetricsData, "url" | "referalUrl">;
export interface IMetricsData {
  language: Language;
  url: string;
  referalUrl: string;
  category?: string;
  urlQuery?: string;
}

export interface IFeatureMetricsData extends IMetricsData {
  feature: string;
  uri?: string;
  searchQuery?: string;
  resultsNumber?: string;
  ticketId?: string;
  ticketReason?: string;
  ticketItem?: string;
  ticketTimespent?: string;
  weblabId?: string;
  weblabTreatment?: string;
}

export enum FeatureMetric {
  USEFUL_LINKS = "useful-links",
  NEW_ARTICLES = "new-articles",
  NEW_ARTICLES_MORE = "new-articles-more",
  RECENTLY_UPDATED = "recently-updated",
  RECENTLY_UPDATED_MORE = "recently-updated-more",
  RELATED_ARTICLES = "related-articles",
  RELATED_ARTICLES_MORE = "related-articles-more", // not currently used, but added for type safety
  NUMBER_OF_SEARCH_RESULTS = "number-of-search-results",
  // This will later on be concatenated with the order of search result
  SEARCH_RESULT = "search_result",
}

export enum Language {
  EN = "en",
}

/**
 *
 * @param url - Can either be full url or pathname
 * @returns full url in http(s)://<domain>/<pathname>
 */
export const getFullUrl = (url: string): string => {
  return new URL(url, `${window.location.protocol}//${window.location.host}`).href;
};

export default class ClickstreamHelper {
  static basePath: string = `${UrlHelper.getApiUrl(UrlHelper.getStage())}/recordMetrics/kb`;

  static async createClickStreamMetric(body: IMetricsData): Promise<void> {
    const url = `${this.basePath}/clickstream`;

    try {
      await HttpClient.postData<IMetricsData>(url, body);
    } catch (err) {
      console.error("Unable to post clickstream metrics", { err });
      throw err;
    }
  }

  static async createFeatureMetric(body: IFeatureMetricsData) {
    const url = `${this.basePath}/featuremetrics`;
    try {
      await HttpClient.postData<IFeatureMetricsData>(url, body);
    } catch (err) {
      console.error("Unable to post feature metrics", { err });
      throw err;
    }
  }
}
