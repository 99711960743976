import "./style.scss";

import { ISearchSuggestionsOutput } from "lib/interfaces/search";
import React, { ChangeEvent, FC, KeyboardEvent, MouseEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import DebounceHelper from "../../../helpers/debounce-helper";
import SearchHelper from "../../../lib/helpers/search-helper";

/**
 * The time (in ms) the debouncer will wait before
 * waiting for the next keyboard input
 */
export const DEBOUNCER_DELAY_IN_MS = 500;
// Debouncer function to fetch search suggestions
let getSearchSuggestions: (this: any, ...args: any[]) => NodeJS.Timeout;

const SearchBar: FC = () => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchSuggestions, setSearchSuggestions] = useState<ISearchSuggestionsOutput>();
  const [showSuggestions, setShowSuggestions] = useState<boolean>(true);
  const history = useHistory();

  const initializeDebouncer = () => {
    getSearchSuggestions = DebounceHelper.getDebouncer(DEBOUNCER_DELAY_IN_MS, async (input) => {
      const output = await SearchHelper.getSearchSuggestions(input?.[0]);
      console.log("output:", output);
      setSearchSuggestions(output);
    });
  };
  const registerClickEventListener = () => window.addEventListener("click", handleClickOutside);
  const unregisterClickEventListener = () => window.removeEventListener("click", handleClickOutside);

  useEffect(() => {
    initializeDebouncer();
    registerClickEventListener();

    return () => {
      unregisterClickEventListener();
    };
  }, []);

  const handleClickOutside = () => {
    setShowSuggestions(false);
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const keywords = e.target.value;
    setShowSuggestions(true);
    setSearchInput(keywords);

    if (!keywords) {
      setShowSuggestions(false);
      setSearchSuggestions({ suggestions: [] });
      return;
    }

    getSearchSuggestions({ keywords: keywords });
  };

  const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (!searchInput) {
      return;
    }
    //e.preventDefault();
    if (e.key === "Enter") {
      // Route to the search page
      history.push(`/search?keywords=${searchInput}`);
    } else if (e.key === "Escape") {
      setShowSuggestions(false);
    }
  };

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();

    setShowSuggestions(true);
  };

  const validSearchSuggestions = () => {
    return searchSuggestions && searchSuggestions.suggestions && searchSuggestions.suggestions.length > 0;
  };

  return (
    <div id="search-bar">
      <input
        type="text"
        placeholder="What can we help you with?"
        value={searchInput}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
      />
      <div id="search-suggestions" className={showSuggestions && validSearchSuggestions() ? "visible" : "invisible"}>
        <SearchSuggestions output={searchSuggestions} />
      </div>
    </div>
  );
};

type props = {
  output: ISearchSuggestionsOutput | undefined;
};

const SearchSuggestions = ({ output }: props) => {
  return (
    <React.Fragment>
      {output?.suggestions.map((suggestion, index) => (
        <a key={index} href={window.location.origin + "/search?keywords=" + suggestion.text}>
          {suggestion.text}
        </a>
      ))}
    </React.Fragment>
  );
};

export default SearchBar;
