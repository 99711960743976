import sentryFetch from "@amzn/sentry-fetch";
import GetCsrfTokenResponse from "helpers/csrf-token";
import UrlHelper from "helpers/url-helper";

export enum HttpMethod {
  Get = "GET",
  Post = "POST",
}

export default class HttpClient {
  private static csrfToken = "";
  private static csrfTokenExpiry = 0;

  static async postData<T>(url: string, body: T): Promise<Response> {
    const request = async () => {
      return sentryFetch(url, {
        method: HttpMethod.Post,
        headers: {
          "Content-Type": "application/json",
          "x-csrf-token": await this.getCsrfToken(),
        },
        body: JSON.stringify(body),
        sentryOptions: {
          followMidwayStepUp: true,
          debug: true,
          preserveRedirectUri: true,
          followMidwayStepUpOptions: {
            mode: "modal",
          },
        },
        credentials: "include",
      });
    };

    return this.execute(request);
  }

  static async getData(url: string): Promise<Response> {
    const request = async () => {
      const headers: Record<string, string> = {};

      return sentryFetch(url, {
        method: HttpMethod.Get,
        sentryOptions: {
          followMidwayStepUp: true,
          debug: true,
          preserveRedirectUri: true,
          followMidwayStepUpOptions: {
            mode: "modal",
          },
        },
        headers: headers,
        credentials: "include",
      });
    };
    return request();
  }

  private static async getCsrfToken(): Promise<string> {
    if (new Date().getTime() >= this.csrfTokenExpiry) {
      const response = await this.getData(`${UrlHelper.getApiUrl(UrlHelper.getStage())}/sso/csrf-token`);
      const csrfTokenObject = (await response.json()) as GetCsrfTokenResponse;
      this.csrfToken = csrfTokenObject.token;
      this.csrfTokenExpiry = csrfTokenObject.expiry;
    }
    return this.csrfToken;
  }

  private static async execute(request: () => Promise<Response>): Promise<Response> {
    try {
      return await request();
    } catch (error) {
      console.error(error);
      this.forceCsrfTokenExpiry(); //Not sure under what circunstances it's required to retry. Not sure when sentryFetch throw.
      return await request();
    }
  }

  static forceCsrfTokenExpiry(): void {
    HttpClient.csrfTokenExpiry = 0;
  }
}
