import { AppLayout } from "common/components";
import ApplicationFavicon from "img/favicon16.png";
import Footer from "modules/components/Footer";
import Header from "modules/components/Header";
import React, { FunctionComponent, useMemo } from "react";
import Favicon from "react-favicon";

import { useAppContext } from "./AppContext";
import Router from "./view/Routes";

const App: FunctionComponent = () => {
  const { isUserProfileReady } = useAppContext();

  const footerSelector = useMemo(() => (isUserProfileReady ? ".kb-footer" : ".placeholder"), [isUserProfileReady]);
  const headerSelector = useMemo(() => (isUserProfileReady ? ".kb-header" : ".placeholder"), [isUserProfileReady]);

  return (
    <div className="awsui">
      <Favicon url={ApplicationFavicon} />

      {isUserProfileReady && <Header />}
      <AppLayout
        data-custom-css="applayout-content"
        content={<Router />}
        maxContentWidth={1024}
        footerSelector={footerSelector}
        headerSelector={headerSelector}
        navigationHide
        toolsHide
      />
      {isUserProfileReady && <Footer />}
    </div>
  );
};

export default App;
