/** Metadata associated with an article */
export interface IArticleMetadata {
  created_at: string;
  full_path: string;
  overview: string;
  title: string;
  updated_at: string;
  category: string;
}

export enum MetadataType {
  CATEGORY = "category",
  ARTICLE_TYPE = "articleType",
  SUPPORT_GROUP = "supportGroup",
}

export enum MetadataDateType {
  CREATED_AT = "created_at",
  UPDATED_AT = "updated_at",
}
