import "./style.css";

import { USER_PHOTO_URL } from "config/constants";
import React, { FC } from "react";

import { getUsername } from "../../../helpers/profile-helper";

const UserPhoto: FC = () => {
  const username = getUsername();
  const user_photo_url = USER_PHOTO_URL + username;
  return <img className="user-photo" src={user_photo_url} alt="Avatar" />;
};

export default UserPhoto;
