import { MetadataType } from "../lib/interfaces/metadata";

export const METADATA_TYPE_TO_PATH: Record<MetadataType, string> = {
  [MetadataType.CATEGORY]: "/categories",
  [MetadataType.ARTICLE_TYPE]: "/article-types",
  [MetadataType.SUPPORT_GROUP]: "/support-groups",
};

export const API_SEARCH_PATH = "/search";
export const API_SEARCH_SUGGESTIONS_PATH = "/search-suggestions";
// CATEGORIES_PATH is set by the MetaField widgets in LegoWidgetsConfig-ITPortal (LegoWidgetsConfig-KB extends it)
export const CATEGORIES_PATH = `${METADATA_TYPE_TO_PATH[MetadataType.CATEGORY]}/:category`;
export const ARTICLE_TYPE_PATH = `${METADATA_TYPE_TO_PATH[MetadataType.ARTICLE_TYPE]}/:category`;
export const SUPPORT_GROUP_PATH = `${METADATA_TYPE_TO_PATH[MetadataType.SUPPORT_GROUP]}/:category`;
export const NEW_ARTICLES_PATH = "/new-articles";
export const SITEMAP_PATH = "/sitemap";
export const UPDATED_ARTICLES_PATH = "/updated-articles";
