import { LANDING_PAGE_SLUG } from "../config/constants";
import { IArticleMetadata } from "../lib/interfaces/metadata";

/** Checks if the article metadata is valid
 * A valid article will have:
 * - a non-empty title
 * - a non-empty url thats not 'landing-page'
 */
export const isValidArticleMetadata = (article: IArticleMetadata) =>
  article.title && article.full_path && !article.full_path.endsWith(LANDING_PAGE_SLUG);
