import { Container, Header } from "common/components";
import React from "react";

interface InternalServerErrorPageProps {
  message?: string;
}

const InternalServerErrorPage = ({
  message = "Sorry, something went wrong. Please try again later!",
}: InternalServerErrorPageProps) => {
  return <Container header={<Header variant="h2">Internal server error!</Header>}>{message}</Container>;
};

export default InternalServerErrorPage;
