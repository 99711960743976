import { IArticleMetadata } from "lib/interfaces/metadata";
import React from "react";

export interface ArticleItemProps extends IArticleMetadata {}

const ArticleItem = (props: ArticleItemProps) => {
  return (
    <article title={props.title} className="article-item">
      <header className="article-title">
        <a href={props.full_path} rel="noopener">
          {props.title}
        </a>
      </header>
      <p>{props.overview}</p>
    </article>
  );
};

export default ArticleItem;
